import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Chris Olin', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'A personal e-folio and website written in JavaScript and React', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'My name is',
  name: 'Chris Olin',
  subtitle: 'I solve complex technical problems and make digital art',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "I'm an accomplished cyclist and jack-of-all-trades technologist with over a decade of industry experience, having filled a variety of roles for various software and technology companies all over the world. Much of the work I have performed over the last five years can be briefly summarized as providing specialized application/software support, refactoring/writing Python scripts, performing electrical hardware repairs/maintenance/upgrades, and resolving logistical problems between manufacturing and sales teams.",
  paragraphTwo:
    "As a person, my view of the world is largely seen through the lens of Stoicism, although it's been influenced by philosophers like Hume, Thoreau, and Kant. In my spare time, I pursue creative and technical endeavors for both personal and professional growth. At a fundamental level of my being, I am a philanthropist and derive much satisfaction from using my technical aptitude to help other people. I have a name reputation in online do-it-yourself electric vehicle communities and years of experience building custom EVs, and find affordable ways to help people unable to drive or afford a car to get around by e-bike and other means using recycled and savaged parts.",
  paragraphThree:
    "For better or worse, western civilization is highly capitalistic. Like every other millennial working in tech, I'm on the grind and have to market myself like a brand or product to find work in this economy. At the same time, it lets me demonstrate to prospective employers that I understand HTML/CSS/JS/React and responsive web design well enough to make this React/Gatsby.JS app.",
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'bikeindex.jpg',
    title: 'Bike Index',
    info1:
      "Bike Index is both a 501(c)(3) non-profit and open source web application written in Ruby that effectively crowdsources the recovery of stolen bicycles. I came to find Bike Index around 2015 while living in Washington, DC. At the time, bike thefts were a daily occurrence and I had initially started tracking thefts in a spreadsheet. After quickly realizing this wasn't scalable, I threw a few choice keywords into Google and discovered Bike Index (much to my excitement at the time).",
    info2:
      "Since then, I've helped facilitate the recovery of multiple stolen bikes, one of which was reported in local news outlets at the time. In recent years, I've stepped back from my role as an unofficial stolen bike vigilante and am more focused on education and prevention. Even still, I am very passionate about this topic.",
    btnTxt1: 'Read news story',
    btnTxt2: 'Visit Bike Index',
    url1: 'https://www.nbcwashington.com/news/local/swiss-tourists-stolen-bike-found-fixed-by-dc-area-cycling-community/2008497/',
    url2: 'https://www.bikeindex.org', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'spectrum.png',
    title: 'Open Source Code Contributor',
    info1:
      'This is essentially cannon fodder for my "webfolio" here so I can bang the open source contributor drum a little louder. In a former life, I was employed by a technology company in the GNSS simulation market who is trying to be open source friendly for the PR and visibilitty boon. Anyways, I contributed to some of the scripts that the development team were working on.',
    info2:
      'As a result, I have extensive experience with GNSS (GPS/Galileo/GLONASS/BeiDou) technologies, how the technology works at a fundamental level, and what most of the players in the industry are doing. I also got to work with some leading companies in aerospace during my time.',
    btnTxt1: 'Learn about GNSS simulation (2 minute video)',
    btnTxt2: 'See the GitHub repo',
    url1: 'https://www.youtube.com/watch?v=U7X-HOedCNE',
    url2: 'https://github.com/learn-orolia/skydel-tools', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'rc3 logo.jpg',
    title: 'GoPro Beta Tester & Hobbyist Digital Artist',
    info1:
      'As an engineer and introvert, art and the humanities never  made intuitive sense or came natural to me. Even still, I have found works of art that I very much appreciate. Recent milestones in modern technology has allowed me to use my technical skills to find some sort of artistic or creative release that multiple people have expressed interest in.',
    info2:
      'That said, GoPro is pioneering digital action camera technology and I am part of an invite-only volunteer beta testing team with direct access to the development team. I have been able to recommend design and UX changes that were eventually accepted by product managers and implemented in stable releases.',
    btnTxt2: "Read about GoPro's general purpose metadata standard",
    btnTxt1: 'Watch some of my art',
    url2: 'https://github.com/gopro/gpmf-parser',
    url1: 'https://www.youtube.com/watch?v=7j5NupitrSk&vq=2160p', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  url: 'https://t.me/realchrisolin',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://www.github.com/realchrisolin',
    },
    {
      id: nanoid(),
      name: 'telegram',
      url: 'https://t.me/realchrisolin',
    },
    {
      id: nanoid(),
      name: 'envelope',
      url: 'mailto:web@chrisolin.com',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
